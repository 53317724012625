import { useCallback, useEffect, useRef, useState } from 'react';

// icons
import {
    IoPlayBackSharp,
    IoPlayForwardSharp,
    IoPlaySkipBackSharp,
    IoPlaySkipForwardSharp,
    IoPlaySharp,
    IoPauseSharp,
    IoVolumeHigh,
    IoVolumeLow,
    IoVolumeOff,
    IoDownload,
  } from 'react-icons/io5';

import { BsRepeat, BsRepeat1, BsShuffle} from "react-icons/bs"

import ProgressBar from './ProgressBar';



const Controls = ({ audioRef, progressBarRef, duration, setTimeProgress, tracks, trackIndex, setTrackIndex, setCurrentTrack, handleNext, handlePrevious, processing, handlePlayPause, isPlaying, repeatState, setRepeatState, skipForward, skipBackward, SKIP_VALUE, volume, setVolume, handleMute, muteVolume}) => {

    const playAnimationRef = useRef()

    const[loop, setLoop] = useState(false)

    const repeat = useCallback(() => {
        const currentTime = audioRef.current.currentTime
        setTimeProgress(currentTime)
        progressBarRef.current.value = currentTime

        //CSS変えたい時はここにかけ
        // progressBarRef.current.style.setProperty(
        //     '--range-progress',
        //     `${(progressBarRef.current.value / duration) * 100}%`
        //   );

        playAnimationRef.current = requestAnimationFrame(repeat)
    },[])

    useEffect(() => {
        if(isPlaying) {
            audioRef.current.play()
        } else {
            audioRef.current.pause()
        }
        playAnimationRef.current = requestAnimationFrame(repeat)
    },[isPlaying, audioRef, repeat, trackIndex])

    useEffect(() => {
        return () => cancelAnimationFrame(playAnimationRef.current)
    },[])


    useEffect(() => {
        if(audioRef){
            audioRef.current.volume = volume / 100
            audioRef.current.muted = muteVolume
        }
    },[volume, audioRef, muteVolume])


    useEffect(() => {
        if(repeatState === "SINGLE"){
            audioRef.current.loop = true
        } else {
            audioRef.current.loop = false
        }
    },[repeatState])




    return(
        <div className="">
            <div className="flex h-14 mb-2 w-full">
                <div className="items-center my-auto">
                    {
                        repeatState === "ALL" ? (
                            <button onClick={() => {
                                setRepeatState("SINGLE")
                                setLoop(true)
                            }}>
                                <BsRepeat size={23}/>
                            </button>
                        ) : repeatState === "SINGLE" ? (
                            <button onClick={() => {
                                setRepeatState("SHUFFLE")
                                setLoop(false)
                            }}>
                                <BsRepeat1 size={23}/>
                            </button>
                        ) : repeatState === "SHUFFLE" ? (
                            <button onClick={() => setRepeatState("ALL")}>
                                <BsShuffle size={23}/>
                            </button>
                        ) : (
                            <button onClick={() => setRepeatState("SINGLE")}>
                                <BsRepeat size={23}/>
                            </button>
                        )
                    }
                </div>
                <div className="flex justify-center gap-4 grow my-auto">
                    <button onClick={handlePrevious}>
                        <IoPlayBackSharp size={30}/>
                    </button>
                    {/* <button onClick={() => skipBackward(5)}>
                        <IoPlayBackSharp/>
                    </button> */}
                    <button onClick={handlePlayPause}>
                        {isPlaying ? <IoPauseSharp size={38}/> : <IoPlaySharp size={38}/>}
                    </button>
                    {/* <button onClick={() => skipForward(5)}>
                        <IoPlayForwardSharp/>
                    </button> */}
                    <button onClick={handleNext}>
                        <IoPlayForwardSharp size={30}/>
                    </button>
                </div>

                {/* <div className='download'>
                    <button>
                        <a href={tracks[trackIndex].src} download={`${tracks[trackIndex].title}.wav`}>
                            <IoDownload/>
                        </a>
                    </button>
                </div> */}

                <div className="my-auto">
                    <button className='mute' onClick={handleMute}>
                        {
                            muteVolume || volume < 1 ? (
                                <IoVolumeOff size={23} />
                            ) : volume < 50 ? (
                                <IoVolumeLow size={23} />
                            ) : (
                                <IoVolumeHigh size={23} />
                            )
                        }
                    </button>
                    { /* <input
                        type="range"
                        min={0}
                        max={100}
                        value={muteVolume ? 0 : volume}
                        onChange={e => setVolume(e.target.value)}
                        /> */}
                </div>
            </div>

        </div>
    )
}

export default Controls