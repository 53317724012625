import { useCallback, useEffect, useRef, useState } from "react"
import { album, tracks } from "../data/tracks"

import "../css/audio_player.css"

import Controls from "./Controls"
import DisplayTrack from "./DisplayTrack"
import ProgressBar from "./ProgressBar"
import TrackList from "./TrackList"
import { useToggle } from "react-use"
import { Helmet } from "react-helmet"

import { CgDarkMode } from "react-icons/cg"


const SKIP_VALUE = 5
const DEFAULT_VOLUME = 90

const AudioPlayer = () => {
    const [isPlaying, setIsPlaying] = useToggle(false)
    const [trackIndex, setTrackIndex] = useState(0)
    const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex])
    const [timeProgress, setTimeProgress] = useState(0)
    const [duration, setDuration] = useState(0)
    const [repeatState, setRepeatState] = useState("ALL")
    const [muteVolume, setMuteVolume] = useState(false)
    const [volume, setVolume] = useState(DEFAULT_VOLUME)
    const [theme, setTheme] = useState("dark")

    //reference
    const audioRef = useRef()
    const progressBarRef = useRef()
    const processingRef = useRef(false)

    const formatTime = (time) => {
        if(time && !isNaN(time)){
            const minutes = Math.floor(time / 60)
            const formatMinutes = (`00${minutes}`).slice(-2)
            const seconds = Math.floor(time % 60)
            const formatSeconds = (`00${seconds}`).slice(-2)
            return `${formatMinutes}:${formatSeconds}`
        }
        return "00:00"
    }


    const skipForward = (time) => {
        processing(() => {
            if(audioRef.current.currentTime + time > duration){
                audioRef.current.currentTime = duration - 0.5
            } else {
                audioRef.current.currentTime += time
            }
        }, 100)
    }

    const skipBackward = (time) => {
        processing(() => {
            audioRef.current.currentTime -= time
        }, 100)
    }


    const handleNext = () => {
        processing(() => {
            switch(repeatState){
                case "ALL":
                    if(trackIndex >= tracks.length - 1) {
                        setTrackIndex(0)
                        setCurrentTrack(tracks[0])
                    } else {
                        setTrackIndex(prev => prev + 1)
                        setCurrentTrack(tracks[trackIndex + 1])
                    }
                    break
                case "SINGLE":
                    // audioRef.current.pause()

                    audioRef.current.currentTime = 0
                    audioRef.current.play()

                    break
                case "SHUFFLE":
                    let randomIndex =  Math.floor( Math.random() * tracks.length )
                    while(randomIndex === trackIndex){
                        randomIndex = Math.floor( Math.random() * tracks.length )
                    }
                    setTrackIndex(randomIndex)
                    setCurrentTrack(tracks[randomIndex])
                    break
                default:

            }
        }, 300)
    }

    const handlePrevious = () => {
        processing(() => {
            if(audioRef.current.currentTime > 0.7){
                audioRef.current.currentTime = 0
            } else {
                switch(repeatState){
                    case "ALL":
                        if(trackIndex === 0) {
                            let lastTrackIndex = tracks.length - 1
                            setTrackIndex(lastTrackIndex)
                            setCurrentTrack(tracks[lastTrackIndex])
                        } else {
                            setTrackIndex(prev => prev - 1)
                            setCurrentTrack(tracks[trackIndex - 1])
                        }
                        break
                    case "SINGLE":
                        audioRef.current.currentTime = 0
                        break
                    case "SHUFFLE":
                        audioRef.current.currentTime = 0
                        setIsPlaying(false)
                        break
                }

            }
        }, 300)
    }

    const handlePlayPause = (boolean) => {
        processing(() => {
            setIsPlaying(boolean)
        }, 100)
    }

    const handleMute = () => {
        processing(() => {
            setMuteVolume(prev => !prev)
        }, 100)
    }

    //連打防止
    const processing = (func ,time) => {
        if(processingRef.current) return false
        processingRef.current = true
        func()
        setTimeout(() => {
            processingRef.current = false
        }, time)
    }

    const handleKeyDown = (e) => {
        // console.log(e);
        if(e.metaKey){
            if(e.code === "ArrowUp"){
                e.preventDefault()
                let v = (volume + 10) > 100 ? 100 : (volume + 10)
                setVolume(v)
            } else if (e.code === "ArrowDown"){
                e.preventDefault()
                let v = (volume - 10) < 0 ? 0 : (volume - 10)
                setVolume(v)
            } else if (e.code === "ArrowLeft"){
                e.preventDefault()
                handlePrevious()
            } else if (e.code === "ArrowRight") {
                handleNext()
            }
        } else {
            if(e.code === "KeyJ"){
                skipBackward(10)
            } else if(e.code === "KeyK" || e.code === "Space") {
                e.preventDefault()
                setIsPlaying()
            } else if(e.code === "KeyL") {
                skipForward(10)
            } else if(e.code === "ArrowLeft") {
                e.preventDefault()
                skipBackward(SKIP_VALUE)
            } else if(e.code === "ArrowRight"){
                e.preventDefault()
                skipForward(SKIP_VALUE)
            } else if(e.code === "Enter"){
                e.preventDefault()
                audioRef.current.currentTime = 0
            } else if(e.code === "KeyM"){
                e.preventDefault()
                handleMute()
            }
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown, false)
        return () => document.removeEventListener("keydown", handleKeyDown)
    },[duration, isPlaying , trackIndex, currentTrack, , repeatState, volume, muteVolume,handleKeyDown, ])

    useEffect(() => {
        if(theme === "dark"){
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    },[theme])


    useEffect(() => {

    },[])

    return(
        <>

            <Helmet>
                <title>{`${tracks[trackIndex].title} / SUGAR.wav`}</title>
                <meta name="robots" content="noindex"></meta>
            </Helmet>


           
            <div className="w-screen">
                {/* ヘッダー */}
                <header className="w-full sticky top-0 z-50 bg-background">
                    <div className="flex w-[calc(100%-2rem)] mx-auto h-14">
                        <h1 className="text-lg m-auto text-left grow items-center font-bold">
                            {album}
                        </h1>

                        <button 
                            className="items-center text-lg"
                            onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
                            <CgDarkMode/>
                        </button>
                    </div>
                </header>


                {/* メインコンテンツ */}
                <div className="w-[calc(100%-2rem)] m-auto">
                    <TrackList {...{
                        handlePlayPause,
                        isPlaying,
                        trackIndex,
                        setTrackIndex,
                        setCurrentTrack,
                        timeProgress,
                        formatTime,
                    }}/>
                </div>

                {/* プレイヤー */}
                <footer className="sticky bottom-0 bg-secondary/90 w-full mx-auto grid grid-rows-1 gap-5">
                    <div className="w-[calc(100%-2rem)] m-auto">
                        <DisplayTrack {...{ 
                            currentTrack, 
                            audioRef, 
                            setDuration, 
                            progressBarRef,
                            handleNext }} />
                        <ProgressBar {...{ 
                            progressBarRef, 
                            audioRef, 
                            timeProgress, 
                            duration,
                            formatTime }}
                            />
                        <Controls {...{ 
                            audioRef, 
                            progressBarRef, 
                            duration, 
                            setTimeProgress,
                            tracks,
                            trackIndex,
                            setTrackIndex,
                            setCurrentTrack,
                            handleNext,
                            handlePrevious,
                            processing,
                            handlePlayPause,
                            isPlaying,
                            repeatState,
                            setRepeatState,
                            skipBackward,
                            skipForward,
                            SKIP_VALUE,
                            volume,
                            setVolume,
                            timeProgress,
                            formatTime,
                            handleMute,
                            muteVolume }}/>
                    </div>

                </footer>

            </div>

        </>
    )
}

export default AudioPlayer