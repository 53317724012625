import { useState } from "react"

import { IoDownload, IoPauseSharp, IoPlaySharp } from "react-icons/io5"
import { AiFillYoutube } from "react-icons/ai"
import { BiSolidCommentDetail } from "react-icons/bi"
import { HiDownload } from "react-icons/hi"


import { tracks } from "../data/tracks"
import ProgressBar from "./ProgressBar"





const TrackList = ({ handlePlayPause, isPlaying, setIsPlaying,trackIndex, setTrackIndex, setCurrentTrack, timeProgress, formatTime }) => {
    const[isLyrics, setIsLyrics] = useState(tracks.map(() => false))

    const handleEachPlayPause = (index) => {
        if(index === trackIndex){
            handlePlayPause()
        } else {
            setTrackIndex(index)
            setCurrentTrack(tracks[index])
            handlePlayPause(true)
        }
    }

    const handleLyrics = (i) => {
        let l = [...isLyrics]
        if(isLyrics[i]){
            l[i] = false
        } else {
            l[i] = true
        }
        setIsLyrics(l)
    }

    
    return (
        <>
            <ul className="w-full grid grid-cols-1
                sm:grid-cols-2 sm:gap-4
                md:grid-cols-3"
            >
                {
                    tracks.map((value, i) => {
                        return (
                            <li className="w-full m-auto border-b border-gray-600/50 mb-4
                                sm:border-b-0
                            "
                                key={i}>
                                <div>
                                    <div className="w-full relative">
                                        {/* サムネイル */}
                                        <img className="w-full" src={tracks[i].thumbnail} alt={tracks[i].title}/>

                                        {/* 歌詞 */}
                                        {
                                            isLyrics[i] ? (
                                                <div className="w-full h-full bg-gray-600/70 absolute top-0 left-1/2 -translate-x-1/2" >
                                                    <div className="w-[calc(100%-2rem)] h-[calc(100%-2rem)] overflow-y-scroll leading-6 text-left text-background dark:text-foreground absolute top-4 left-4">
                                                    {
                                                        tracks[i].lyrics.map((value, index) => {
                                                            return value.map((_value, _index)=> {
                                                                if(_index < value.length - 1){
                                                                    return (
                                                                        <p key={_index}>{_value}</p>
                                                                    )
                                                                } else {
                                                                    return(
                                                                        <p className="mb-7" key={_index}>{_value}</p>
                                                                    )
                                                                }

                                                            })
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                            ) : <></>
                                        }

                                    </div>

                                    <div className="flex my-4">
                                        {/* 再生停止ボタン */}
                                        <button className="rounded-full bg-gray-600/50 w-20 h-20"
                                            onClick={() => handleEachPlayPause(i)}>
                                            <div className="m-auto w-full">
                                                {isPlaying && i === trackIndex ? 
                                                    <IoPauseSharp size={40} className="m-auto text-background dark:text-foreground "/> : 
                                                    <IoPlaySharp size={40} className="m-auto text-background dark:text-foreground"/>
                                                }
                                            </div>
                                        </button>

                                        
                                        <div className="grid columns-1 grow">

                                            {/* タイトル */}
                                            <div className="w-full">
                                                <p className="text-right text-lg">{value.title}</p>
                                            </div>

                                            {/* ボタン */}
                                            <div className="w-full flex gap-3 justify-end items-end">


                                                {/* Youtube */}
                                                {
                                                    tracks[i].youtube ? (
                                                    <button className="rounded-full bg-gray-600/50 w-8 h-8 text-background dark:text-foreground">
                                                        <a href="https://www.youtube.com/watch?v=TimXp5BYGEY" target="_blank">
                                                            <AiFillYoutube size={18} className="m-auto"/>
                                                        </a>
                                                    </button> ) : <></>
                                                }

                                                {/* 歌詞 */}

                                                <button className="rounded-full bg-gray-600/50 w-8 h-8 text-background dark:text-foreground"
                                                    onClick={() => handleLyrics(i)}
                                                >
                                                    <BiSolidCommentDetail size={17} className="m-auto"/>
                                                </button>




                                                {/* ダウンロード */}

                                                <button className="rounded-full bg-gray-600/50 w-8 h-8 text-background dark:text-foreground">
                                                    <a href={value.src} download={`${value.title}.wav`}>
                                                        <HiDownload size={18} className="m-auto"/>
                                                    </a>
                                                </button>
                                            </div>                                       
                                        </div>
                                    </div>


                                    {/* <div>
                                        {
                                            i === trackIndex ? formatTime(timeProgress) : ""
                                        }
                                    </div> */}
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}

export default TrackList