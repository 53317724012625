//audio files
import masui from './src/masui.wav'
import myojo from './src/myojo.wav'
import yn from './src/yesno.wav'
import swl from './src/sugarwavylove.wav'
import aishiteru from './src/114106.wav'
import kitaihazure from './src/kitaihazure.wav'

//audio thumbnails
import myojoImg from "./thumbnails/myojo.jpg"
import masuiImg from "./thumbnails/masui.jpg"
import ynImg from "./thumbnails/yes-no.jpg"
import swlImg from "./thumbnails/sugar-wavy-love.png"
import noThumbnail from "./thumbnails/nothumbnail.png"

export const album = "SUGAR.wav #1"

export const tracks = [
    {
        title: "明星",
        src: myojo,
        author: "THE BAD END",
        thumbnail: myojoImg,
        duration: "03:06",
        youtube: "https://www.youtube.com/watch?v=TimXp5BYGEY",
        lyrics: [
            [
            "ああ 夢は覚めないで 続いて",
            "まだ あなたと話したい",
            "ああ やがて 目覚めてゆく",
            "その瞬間に 全てを思い知る",
            ],
            [
                "一つ星がいくら輝けど",
                "二度と心の隙間は 埋まらなくて",
                "再三再四 涙したから",
                "今日のところはおやすみ",
            ],
            [
                "ああ 夢は覚めないで 続いて",
                "まだ あなたと話したい",
                "ああ やがて 目覚めてゆく",
                "その瞬間に 全てを思い知る",
            ],
            [
                "ああ 願おう 明星",
                "五臓六腑を締めつける朝を",
                "隠して 隠して 隠して",
            ],
            [
                "ああ やがて 目覚めてゆく",
                "その瞬間も あなたに恋をする",
            ],
            [
                "ああ 夢は覚めないで 続いて"
            ],
        ]
    },

    {
        title: "麻酔",
        src: masui,
        author: "THE BAD END",
        thumbnail: masuiImg,
        duration: "04:23",
        lyrics: [
            ["あなたのように 強くはなれない",
            "そう思うわたし 壊れかけの夜に",
            "誰かの嫌味 上手く避けれない",
            "かすめるその距離に 命すり減らし",],

            ["あなたの唇が麻酔だと",
            "気づいた時には わたしはもう麻痺してる",
            "喉奥深く隠し続けた思いを",
            "今ならば 今ならば さらけ出せる"],

            ["「ここにおいで」ただそれだけ",
            "「そばにおいで」一言だけ呟いて",
            "頬を撫でて 甘く触れて",
            "そして許して わたしはわたしでいいと"],

            ["あなたのように 上手く笑えない",
            "そう思うわたし へちゃげたエントロピー"],

            ["あなたの唇が麻酔だと",
            "気づいた時には わたしはもう麻痺してる",
            "固く閉ざして鍵を掛けた心を",
            "今ならば 今ならば 開け放てる"],

            ["「ここにおいで」ただそれだけ",
            "「そばにおいで」一言だけ呟いて",
            "頬を撫でて 甘く触れて",
            "そして許して 痛みは過去へ"],

            ["「ここにおいで」ただそれだけ",
            "「そばにおいで」一言だけ呟いて",
            "頬を撫でて 甘く触れて",
            "そして許して わたしはわたしでいいと"],

        ]
    },

    {
        title: "Yes/No",
        src: yn,
        author: "THE BAD END",
        thumbnail: ynImg,
        duration: "03:14",
        lyrics: [
            ["もしあの時もっと",
            "早く夢からそう目覚めていたとしたら"],

            ["冷めやらぬ温度",
            "それもただのごっこ遊びだったの答えて"],

            ["雲は形を変え",
            "私は影さえ失ってもう隠せない"],

            ["いつしか雨が上がり 日差しが窓をノックする",
            "Golden spring turning into blue summer"],

            ["If I said yes",
            "If I said no",
            "何か変わってた？"],

            ["Why I can’t see",
            "もどかしい",
            "透かせたら良いのに"],

            ["大事に宝石箱に入れていたら",
            "春は解けなかったかな？",
            "優先順位がわからなくなって",
            "何もかも見失った"],

            ["その幸せはお互いのためだって",
            "はっきり言えなきゃ意味がないの"],

            ["答え合わせができないままでは",
            "きっとこれ以上前に進めない"],

            ["1人で漕いでいた",
            "テセウスの舟は"],

            ["汲んでも 減らない",
            "水に沈んでいく"],

            ["If I said yes",
            "If I said no",
            "何か変わってた？"],

            ["We should’ve said yes",
            "We should’ve said no",
            "きっと変わってた"],

            ["傷つくことが私らしいなんて",
            "思えば思うほど惨めで楽なんだ",
            "誰かの作った普通の幸せに合わせないで",
            "Don’t listen to them",
            "自分の意思で Say yes or no"],
        ],
    },


    {
        title: "Sugar Wavy Love",
        src: swl,
        author: "THE BAD END",
        thumbnail: swlImg,
        duration: "04:19",
        lyrics: [
            ["Sugar Wavy Love",
            "愛なんて知りたくなかった",
            "Sugar Wavy Love",
            "この夜は涙のループ"],

            ["相も変わらず 地球は回る",
            "意味のないことを 切りのないことを",
            "憎んだとしても 不毛"],

            ["何もわからず 生まれたんだ 全部",
            "音を立てきしむ 根拠ない自信",
            "真冬の蜃気楼のよう"],

            ["(Sugar Wavy Love, Sugar Wavy Love)",
            "甘い嘘で騙し合って",
            "二人の恋路などやがて 離ればなれ",
            "人生というままごとに耽る"],

            ["(Sugar Wavy Love, Sugar Wavy Love)",
            "もはや感情などなくて",
            "痛みに浸る間もなくて ただ今だけ",
            "愛すべき誰かを探している"],

            ["Sugar Wavy Love",
            "愛なんて知りたくなかった",
            "Sugar Wavy Love",
            "この星は涙がルール",
            "その支配を抜け出して 幸せになろう"],

            ["Sugar Wavy Love",
            "愛なんて知りたくなかった",
            "Sugar Wavy Love",
            "この夜は涙のループ"],
        ],
    },

    {
        title: "1141♡6",
        src: aishiteru,
        author: "THE BAD END",
        thumbnail: noThumbnail,
        duration: "03:10",
        lyrics: [
            ["深夜のレジ 打ちこむ戦士",
            "文字通り 人々の金を処理",
            "あたためレンジ いそいそチンし",
            "労働切り売り これぞ後期資本主義"],
            
            ["わかる（わかる） わかる（わかる）",
            "その共感が 走り出した虫酸の原因",
            "知らぬ人のその悪意だけ",
            "脳にこびりつく"],
            
            ["「僕ら分かり合えない」と",
            "その点で分かり合えるだろう",
            "意外に気が合うかもね",
            "けれど もうどっか行け"],
            
            ["「僕ら分かり合えない」と",
            "その点で分かり合えるだろう",
            "最高の共通点だね",
            "だから もうどっか行け"],
            
            ["心臓麻痺 一応回避",
            "シフトを颯爽と退勤 話しかけないでほしい",
            "1•2•3•4 誤差は処罰に",
            "札束が一番大事 この世の偽の理"],
            
            ["わかる（わかる） わかる（わかる）",
            "その共感が 走り出した虫酸の原因",
            "重ね重ね 黙れ（黙れ）",
            "たまには頭で ちゃんと考えて"],
            
            ["アッチョンブリケ"],
            
            ["「僕ら分かり合えない」と",
            "その点で分かり合えるだろう",
            "意外に気が合うかもね",
            "けれど もうどっか行け"],
            
            ["「僕ら分かり合えない」と",
            "その点で分かり合えるだろう",
            "最高の共通点だね",
            "だから もうどっか行け"],
        ],
    },

    {
        title: "期待外れ",
        src: kitaihazure,
        author: "THE BAD END",
        thumbnail: noThumbnail,
        duration: "04:10",
        lyrics: [
            ["君の代わりはいつも",
            "どこにでもいると思う",
            "たわいもなさだけはもう",
            "世界一を取れるよ"],

            ["SNSで映えそうな",
            "加工された偽物に",
            "成り下がった生き物",
            "耐えられないよ これ以上"],

            ["始まりに戻れるなら",
            "脳天を突き刺すような",
            "舞台女優に引けをとらない",
            "あの眼差しをくれ ほら"],

            ["今はただ惰性の仲",
            "もはや気の抜けたコーラ",
            "意味も意義も何もかもを",
            "感じられないまま"],

            ["期待外れ 道半ばで",
            "二人は分かれて 分かれてゆく",
            "思い倦ね 背中合わせ",
            "二人は変わって 変わってゆく"],

            ["期待外れ 未完成の夢",
            "二人は分かれて 分かれてゆく",
            "頭の奥で 答え合わせ",
            "二人は変わって 変わってゆく"],

            ["We were just friends ",
            "Just one of those things that ends",
            "Doesn’t matter how much time we spend, he pretends",
            "Gosh I was really into his smile through the lens",
            "But look how awkwardly it went",
            "Maybe I was the only one round the bend round the bend"],

            ["いつものカフェで会おうよ今から",
            "言葉なんていらないよ今さら",
            "わかったような気がしてたの",
            "私にだけ見せてその笑顔"],

            ["置いてって残したモヒート",
            "そういうのもういいよ",
            "無かったことにする方法",
            "私たちどうする？",
            "Give me some more ヒント"],

            ["もう会う気はないよさよなら",
            "言葉なんかじゃ足りないなおさら",
            "泣けないくらい あっけないね",
            "Well...I guess this is the end"],

            ["期待外れ 道半ばで",
            "二人は分かれて 分かれてゆく",
            "思い倦ね 背中合わせ",
            "二人は変わって 変わってゆく"],

            ["期待外れ 未完成の夢",
            "二人は分かれて 分かれてゆく",
            "頭の奥で 答え合わせ",
            "二人は変わって 変わってゆく"]
        ],
    },
]