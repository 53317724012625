import { useEffect, useState } from "react"

const ProgressBar = ({ progressBarRef, audioRef, timeProgress, duration, formatTime }) => {
    const handleProgressChange = () => {
        audioRef.current.currentTime = progressBarRef.current.value
    }

    const[prevTime, setPrevTime] = useState(0)

    const updateSlider = () => {
        const p = (timeProgress / duration) * 100
        if(progressBarRef){
            progressBarRef.style = `linear-gradient(to right, red ${p}%, blue ${p}%)`
        }
   }

    useEffect(() => {
        updateSlider()

        let pt = Math.floor(prevTime)
        let ct = Math.floor(timeProgress)
        if(pt !== ct){
            setPrevTime(timeProgress)
        } 
    }, [timeProgress])

    return(
        <div className="progress">
            <input
             type="range" 
             ref={progressBarRef}
             defaultValue="0"
             onChange={handleProgressChange}

             className="
                w-full
                appearance-none
                h-2
                cursor-pointer
                bg-transparent
                [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-foreground [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:drop-shadow-none [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-background/70
             "
             />
             <div className="w-full flex text-xs ">
                <span className="">{formatTime(timeProgress)}</span>
                {/* <span className="grow text-right"> {formatTime(duration)}</span> */}
                <span className="grow text-right">-{formatTime(duration - prevTime)}</span>
             </div>

        </div>
    )
}

export default ProgressBar