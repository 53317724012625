import top from "./data/top.png"

import { AiFillYoutube } from "react-icons/ai"
import { FaTiktok } from "react-icons/fa"
import { BsTwitter, BsInstagram } from "react-icons/bs"

import { Helmet } from "react-helmet"

const Home = () => {
    return(
        <>
            <Helmet>
                <title>SUGAR.wav Official</title>
                <meta name="robots" content="index follow"></meta>
            </Helmet>
            <div className="w-[calc(100%-2rem)] h-screen h-[100svh] mx-auto my-auto flex">
                <img className="m-auto items-center" src={top} />
            </div>
            <div className="fixed bottom-0 left-1/2 -translate-x-1/2 flex gap-10 justify-center my-8">
                <button>
                    <a href="https://www.youtube.com/@SUGAR_wav" target="_blank">
                        <AiFillYoutube size={28} />
                    </a>
                </button>
                <button>
                    <a href="https://twitter.com/sugar_wav" target="_blank">
                        <BsTwitter size={28} />
                    </a>
                </button>
                <button>
                    <a href="https://www.instagram.com/sugar.wav_official/" target="_blank">
                        <BsInstagram size={28} />
                    </a>
                </button>
                <button>
                    <a href="https://www.tiktok.com/@sugar.wav_official?is_from_webapp=1&sender_device=pc" target="_blank">
                        <FaTiktok size={28} />
                    </a>
                </button>
            </div>
        </>
    )
}

export default Home