import logo from './logo.svg';
import './App.css';
// import Player from './ep/Player';

import { Routes, Route } from 'react-router-dom';
import Home from './home/Home';
import SugarWavNo1 from './routes/SugarWavNo1';
import AudioPlayer from './ep/component/AudioPlayer';


function App() {
  return (
    <div className="App">
      {/* <Player/> */}
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/sugar-wav-no1" element={<AudioPlayer/>}/>
        <Route path="*" element={<Home/>}/>
      </Routes>
    </div>
  );
}

export default App;
