import { BsMusicNoteBeamed } from 'react-icons/bs'


const DisplayTrack = ({ currentTrack, audioRef, setDuration, progressBarRef, handleNext }) => {
    const onLoadedMetadata = () => {
        const seconds = audioRef.current.duration
        setDuration(seconds)
        progressBarRef.current.max = seconds
    }

    return(
        <div>
            <audio
                src={currentTrack.src}
                ref={audioRef}
                preload="metadata"
                onLoadedMetadata={onLoadedMetadata}
                onEnded={handleNext}
                />
            {/* <div className='audio-info'>
                {
                    currentTrack.thumbnail ? (
                        <img src={currentTrack.thumbnail} alt="audio avatar"/>
                    ) : (
                        <div>
                            <span className='audio-icon'>
                                <BsMusicNoteBeamed/>
                            </span>
                        </div>
                    )
                }
            </div> */}
            <div className="text-sm mt-2 mb-1">
                <h2 className="text-sm">{currentTrack.title}</h2>
                {/* <p>{currentTrack.author}</p> */}
            </div>
        </div>
    )

}


export default DisplayTrack